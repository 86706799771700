<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Sortir" collapsed :badge="$store.getters['stockouts/isFiltered'] ? 'Filtered':''">
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select-new :filter-id="idBranchArray" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.branch" label="Cabang Asal"></form-select-new>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select-static :options="utils.statuses" v-model="filter.id_status" label="Status"></form-select-static>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
                <button-action v-if="cP(135)" size="sm" @clicked="exportExcel" variant="warning" name="Export"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Stok Keluar / Penggunaan">
        <template slot="button">
          <button-add v-if="cP(98)" :to="{name: 'stockouts-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables sort-key="2" desc ref="datatables" endpoint="v1/stock_outs" :query-params="filterQuery" :fields="fields">
            <template v-slot:2="{item}">
              {{item[2] | mindate}}
            </template>
            <template v-slot:3="{item}">
              <b-badge :variant="utils.statusTable[item[10]].color" pill @click="showMessageStatus(item[11], item[0], item[10])">
                {{item[3]}}
                <feather-icon v-if="utils.statusTable[item[10]].icon" :icon="utils.statusTable[item[10]].icon"></feather-icon>
              </b-badge>
            </template>
            <template v-slot:4="{item}">
              <dl class="row">
                <dt class="col-sm-6 text-right">Total Penjualan :</dt>
                <dt class="col-sm-6">{{item[4] | formatNumber}}</dt>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">Total Waste :</dt>
                <dt class="col-sm-6">{{item[6] | formatNumber}}</dt>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">Total u/ Karyawan :</dt>
                <dt class="col-sm-6">{{item[7] | formatNumber}}</dt>
              </dl>
              <dl class="row">
                <dt class="col-sm-6 text-right">Total Lain-lain :</dt>
                <dt class="col-sm-6">{{item[8] | formatNumber}}</dt>
              </dl>
              <hr>
              <dl class="row">
                <dt class="col-sm-6 text-right">Total Akhir :</dt>
                <dt class="col-sm-6">{{item[9] | formatNumber}}</dt>
              </dl>
            </template>
            <template v-slot:5="{item}">
              <div class="float-none">
                <button-link v-if="cP(100)" size="sm" :to="{name:'stockouts-edit',params:{id: item[5]}}" feather-icon="EditIcon"></button-link>
                <button-delete v-if="cP(101)" @ondelete="deleteRow" :id="item[5]" :name="`${item[0]} (${item[1]})`"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'
import FormSelectNew from '@/my-components/FormSelectNew'
import FormSelectStatic from '@/my-components/FormSelectStatic'
import FormDate from '@/my-components/FormDate'
import { mapFields } from 'vuex-map-fields'
import {BBadge} from 'bootstrap-vue'
import utils from './utils'
import FileSaver from 'file-saver'

export default {
  components:{
    DataTables,
    ButtonDelete,
    ButtonLink,
    FormSelectNew,
    FormDate,
    ButtonAction,
    ButtonAdd,
    BBadge,
    FormSelectStatic
  },
  data() {
    return {
      utils:utils,
      fields: {
        0:{sortable: true, searchable: true, label:'Kode'},
        1:{sortable: true, searchable: true, label:'Cabang'},
        2:{sortable: true, searchable: true, label:'Tanggal'},
        3:{sortable: true, searchable: true, label:'Status'},
        4:{sortable: true, searchable: true, label:'Total Stok Keluar'},
        5:{sortable: false, searchable: false, label:''},
      }
    }
  },
  computed:{
    ...mapFields('stockouts',['filter']),
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
    filterQuery(){
      let filters = JSON.parse(JSON.stringify(this.filter))
      if(filters.branch) filters.id_branch = filters.branch.value
      delete filters.branch
      return filters
    },
  },
  methods:{
    firstInit(){
      if(!this.isPusat) {
        if(!this.filter.branch)
        this.filter.branch = {value:this.userProfile.id_branch,label:this.userProfile.branch_name}
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      if(this.isPusat){
        this.filter.id_branch = ""
        this.filter.branch = null
      }
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.filter.id_status = ""
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('stockouts/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async exportExcel(){
      const filters = this.filter
      try {
        const res = await this.$http.get(`v1/stock_out_export`,{params:filters,responseType: 'blob'})
        FileSaver.saveAs(res.data,'STOK KELUAR')
      } catch (error) {
        this.handleError(error)
      }
    },
    showMessageStatus(data, code, status) {
      if(!data || [3,4,5].includes(Number(status))) return null;

      const body = JSON.parse(data);
      let err = `<p class="text-weight-bold" style="font-size: 20px; font-weight: bold;">Stok Keluar dengan kode ${code} belum terverifikasi karena terdapat permasalahan berikut :</p> <br>`
      
      let icon, title;
      if(Number(body?.status) === 200){
        for (let i = 0; i < body?.message?.length; i++) {
          err+=`${i+1}. ${body?.message?.[i]}<br>`
        }
        title = 'Peringatan'
        icon = 'warning'
      }else{
        err += `1. ${JSON.stringify(body?.message) || ''}<br>`
        title = 'Error'
        icon = 'error'
      }
      
      err+=`<br><p class="text-weight-bold" style="font-size: 20px; font-weight: bold;">Klik tombol Ya untuk menutup pesan ini</p>`
      
      this.$swal({
        title,
        html: err,
        icon,
        showCancelButton: false,
        confirmButtonText:`Ya`,
        width: '30%',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    }

  },
  created(){
    this.firstInit()
  }
}
</script>

<style>

</style>